import React from "react"
import "./index.scss"
import { SanitySimpleSection } from "@utils/globalTypes"
import { BaseBlockContent } from "@utils/richText"
import { Row, Col } from "react-bootstrap"

type Props = {
  sanitySimpleSection: SanitySimpleSection
}

const SimpleSection = ({ sanitySimpleSection }: Props) => {
  return (
    <section
      className="simpleSection-container"
      style={
        sanitySimpleSection?.backgroundColor
          ? { backgroundColor: sanitySimpleSection.backgroundColor }
          : {}
      }
    >
      <Row className="simpleSection-text">
        {sanitySimpleSection?.title && (
          <Col md={12} xl={6} lg={6}>
            <h1>{sanitySimpleSection.title}</h1>
          </Col>
        )}
        {sanitySimpleSection?._rawContent && (
          <Col className="lg:pr-4" md={12} xl={6} lg={6}>
            <BaseBlockContent
              blocks={sanitySimpleSection._rawContent}
              className="simpleSection-block"
            />
          </Col>
        )}
      </Row>
    </section>
  )
}

export default SimpleSection

import React from "react"
import "./index.scss"
import { SanityHeroWithCta } from "@utils/globalTypes"
import { BaseBlockContent } from "@utils/richText"
import { Col, Row } from "react-bootstrap"
import Image from "gatsby-image"
import CtaButtonComponent from "@components/CtaButton"

type Props = {
  sanityHeroWithCta: SanityHeroWithCta
}

const HeroWithCTA = ({ sanityHeroWithCta }: Props) => {
  return (
    <section
      style={
        sanityHeroWithCta.backgroundColor
          ? { backgroundColor: sanityHeroWithCta.backgroundColor }
          : {}
      }
    >
      <Row noGutters>
        {sanityHeroWithCta.image && (
          <Col className="hero-image-left">
            <Image
              fluid={sanityHeroWithCta.image?.asset?.fluid}
              alt={sanityHeroWithCta.image.alt}
            />
          </Col>
        )}
        {sanityHeroWithCta._rawContent && (
          <Col>
            <Row className="hero-content">
              <Row className="hero-block">
                <BaseBlockContent blocks={sanityHeroWithCta._rawContent} />
              </Row>
              {sanityHeroWithCta.image && (
                <Col xs={12} className="center-image-container">
                  <Image
                    fluid={sanityHeroWithCta.image?.asset?.fluid}
                    alt={sanityHeroWithCta.image.alt}
                    className="center-image"
                    imgStyle={{ objectFit: "contain" }}
                  />
                </Col>
              )}
              <Row className="hero-cta-container">
                {sanityHeroWithCta.ctaButtons.length &&
                  sanityHeroWithCta.ctaButtons.map((item, index) => (
                    <CtaButtonComponent key={index} ctaButton={item} />
                  ))}
              </Row>
            </Row>
          </Col>
        )}
      </Row>
    </section>
  )
}

export default HeroWithCTA

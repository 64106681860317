import React, { useState, useEffect } from "react"
import "./index.scss"
import { FaArrowUp } from "@react-icons/all-files/fa/FaArrowUp"
import ArrowUpIcon from "@assets/svg/ArrowUpIcon"
import useResizeWidth from "@hooks/useResizeWidth"

type Props = {
  style?: object
  type?: "ligth" | "dark"
}

const ScrollUpButton = ({ style = {}, type = "dark" }: Props) => {
  const [visible, setVisible] = useState(false)
  const { width } = useResizeWidth()

  const toggleVisible = () => {
    const footer = document.getElementById("footer")
    if (
      window?.pageYOffset > 400 &&
      window.pageYOffset + window.innerHeight < footer.offsetTop + 200
    ) {
      setVisible(true)
    } else {
      setVisible(false)
    }
  }

  const scrollToTop = () => {
    document.body.scrollTo({
      top: 0,
      behavior: "smooth",
    })
    document.documentElement.scrollTo({
      top: 0,
      behavior: "smooth",
    })
    document.body.focus()
  }

  useEffect(() => {
    if (window !== "undefined") {
      window?.addEventListener("scroll", toggleVisible)
    }
    return () => {
      if (window !== "undefined")
        window?.removeEventListener("scroll", toggleVisible)
    }
  }, [])

  return (
    <button
      id="scroll-up-btn"
      className={`scroll-up-button scroll-up-${type}`}
      onClick={scrollToTop}
      style={{ display: width < 640 || visible ? "inline" : "none", ...style }}
    >
      <ArrowUpIcon />
    </button>
  )
}

export default ScrollUpButton

import React from "react"
import "./index.scss"
import {
  cta_button_types,
  SanityBlogPost,
  SanityContentSection,
  SanityRawText,
} from "@utils/globalTypes"
import { BaseBlockContent } from "@utils/richText"
import { Row, Col, Card } from "react-bootstrap"
import CtaButtonComponent from "@components/CtaButton"
import Image from "gatsby-image"
import { Link } from "gatsby"

type Props = {
  contentSection: SanityContentSection
}

const AlignedWithLineContentSection = ({ contentSection }: Props) => {
  const formatDate = (dateString: string) => {
    const date = dateString ? new Date(dateString) : new Date()
    const day =
      date.getDate() + 1 >= 10 ? date.getDate() + 1 : `0${date.getDate() + 1}`
    const month =
      date.getMonth() + 1 >= 10
        ? date.getMonth() + 1
        : `0${date.getMonth() + 1}`
    const year = date.getFullYear().toString().substring(2)
    return `${month}/${day}/${year}`
  }  

  return (
    <section
      style={
        contentSection.backgroundColor
          ? { backgroundColor: contentSection.backgroundColor }
          : {}
      }
    >
      <div className="contentSection">
        <h2 className={`contentSection-title-${contentSection.type}`}>
          {contentSection.title}
        </h2>
        <Row className={`${contentSection.type}`} noGutters>
          {contentSection.content.map((item, index) => {
            //@ts-ignore
            if (item._type === "blogPost") {
              const post = item as SanityBlogPost
              const textBody = post?._rawArticle
                .map((item) =>
                  item?.children?.map((child) => child?.text || "")
                )
                .join("")
              return (
                <div
                  key={index}
                  className={`contentSection-${contentSection.type} col`}
                >
                  <div>
                    <Card style={{ width: "100%" }}>
                      <div className="ucs-article-image">
                        <div className="image-with-cta">
                          <div className="hovered-imageButton">
                            <CtaButtonComponent
                              ctaButton={{
                                linkUrl: `/viewpoints${post.slug.current}`,
                                title: "Read More",
                                buttonType: cta_button_types.tertiary,
                              }}
                            />
                          </div>
                        </div>
                        <Image
                          fluid={post?.image?.asset?.fluid}
                          alt={post?.image?.alt}
                        />
                      </div>
                      <Card.Body className="unlg-contentSection-unaligned">
                        <h5>{formatDate(post?.date)}</h5>
                        <h3>{post.title}</h3>
                        <Card.Text>
                          {textBody.substring(0, 120)}...{" "}
                          <Link to={`/viewpoints${post?.slug?.current}`}>
                            Read more
                          </Link>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              )
            } else {
              const block = item as { _rawContent: SanityRawText[] }
              return (
                <div
                  key={index}
                  className={`contentSection-${contentSection.type} col`}
                >
                  <BaseBlockContent blocks={block._rawContent} />
                </div>
              )
            }
          })}
        </Row>
        <Row className="ctaButtonContainer-aligned" noGutters>
          {contentSection?.ctaButton?.length > 0 &&
            contentSection?.ctaButton?.map((item, index) => (
              <CtaButtonComponent key={index} ctaButton={item} />
            ))}
        </Row>
      </div>
    </section>
  )
}

export default AlignedWithLineContentSection

import React, { useState } from "react"
import "./index.scss"
import { cta_button_types, SanityBlogPost, SanityContentSection, SanityRawText, SanityBlog } from "@utils/globalTypes"
import { BaseBlockContent } from "@utils/richText"
import { Row, Card } from "react-bootstrap"
import CtaButtonComponent from "@components/CtaButton"
import useResizeWidth from "@hooks/useResizeWidth"
import Carousel from "react-elastic-carousel"
import Image from "gatsby-image"
import { graphql, Link } from "gatsby"

type Props = {
  contentSection: SanityContentSection
}

const Wrapper = ({ children, index }) => {
  const { width } = useResizeWidth()
  return (
    <div key={index} className={`unlg-contentSection-unaligned ${width > 961 && index % 2 ? "pt-0" : "pt-5"} col`}>
      {children}
    </div>
  )
}

const UnalignedContentSection = ({ contentSection }: Props) => {
  const { width } = useResizeWidth()
  const [activeSlide, setActiveSlide] = useState(1)

  const formatDate = (dateString: string) => {
    const date = dateString ? new Date(dateString) : new Date()
    const day = date.getDate() + 1 >= 10 ? date.getDate() + 1 : `0${date.getDate() + 1}`
    const month = date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`
    const year = date.getFullYear().toString().substring(2)
    return `${month}/${day}/${year}`
  }

  return (
    <section style={{ backgroundColor: "#fff5ec" }}>
      <div className="unlg-contentSection">
        <div className={`unlg-contentSection-title`}>
          <h2>{contentSection.title}</h2>{" "}
        </div>
        {width > 961 ? (
          <Row className={"unlg-contentSection-unaligned"}>
            {contentSection.content.map((item, index) => {
              //@ts-ignore
              if (item._type === "blogPost") {
                const post = item as SanityBlogPost
                const textBody = post?._rawArticle
                  .map((item) => item?.children?.map((child) => child?.text || ""))
                  .join("")
                return (
                  <Wrapper key={index} index={index}>
                    <Card style={{ width: "100%" }}>
                      <div className="ucs-article-image">
                        <div className="image-with-cta">
                          <div className="hovered-imageButton">
                            <CtaButtonComponent
                              ctaButton={{
                                linkUrl: `/viewpoints${post.slug.current}`,
                                title: "Read More",
                                buttonType: cta_button_types.tertiary,
                              }}
                            />
                          </div>
                        </div>
                        <Image fluid={post?.image?.asset?.fluid} alt={post?.image?.alt} />
                      </div>
                      <Card.Body className="unlg-contentSection-unaligned">
                        <h5>{formatDate(post?.date)}</h5>
                        <h3>{post.title}</h3>
                        <Card.Text>
                          {textBody.substring(0, 120)}...{" "}
                          <Link to={`/viewpoints${post?.slug?.current}`}>Read more</Link>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Wrapper>
                )
              } else {
                const block = item as { _rawContent: SanityRawText[] }
                return (
                  <Wrapper key={index} index={index}>
                    <BaseBlockContent blocks={block._rawContent} />
                  </Wrapper>
                )
              }
            })}
          </Row>
        ) : (
          <Carousel
            itemPadding={[0, 5]}
            itemsToShow={1}
            outerSpacing={50}
            isRTL={false}
            pagination={false}
            transitionMs={300}
            initialActiveIndex={1}
            showArrows={false}
            className="unlg-carousel-container"
          >
            {contentSection.content.map((item, index) => {
              //@ts-ignore
              if (item._type === "blogPost") {
                const post = item as SanityBlogPost
                return (
                  <Card
                    style={{ width: "100%" }}
                    className="unlg-carousel-cell unlg-contentSection-unaligned"
                    key={index}
                    onClick={() => setActiveSlide(index)}
                  >
                    <div className="ucs-article-image">
                      <div className="image-with-cta">
                        <div className="hovered-imageButton">
                          <CtaButtonComponent
                            ctaButton={{
                              linkUrl: `/viewpoints/${post.slug.current}`,
                              title: "Read More",
                              buttonType: cta_button_types.tertiary,
                            }}
                          />
                        </div>
                      </div>
                      <Image fluid={post?.image?.asset?.fluid} alt={post?.image?.alt} />
                    </div>
                    <Card.Body className="blog-card-body">
                      <h5>{formatDate(post?.date)}</h5>
                      <h3>
                        <Link to={`/viewpoints${post.slug.current}`}>{post.title}</Link>
                      </h3>
                    </Card.Body>
                  </Card>
                )
              } else {
                const block = item as { _rawContent: SanityRawText[] }
                return (
                  <div
                    key={index}
                    onClick={() => setActiveSlide(index)}
                    className={`unlg-carousel-cell unlg-contentSection-unaligned`}
                  >
                    <BaseBlockContent blocks={block._rawContent} />
                  </div>
                )
              }
            })}
          </Carousel>
        )}
        <Row className="ctaButtonContainer" noGutters>
          {contentSection?.ctaButton?.length > 0 &&
            contentSection?.ctaButton?.map((item, index) => <CtaButtonComponent key={index} ctaButton={item} />)}
        </Row>
      </div>
    </section>
  )
}

export default UnalignedContentSection

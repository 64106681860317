import React from "react"

const ArrowUpIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="244"
      height="241"
      viewBox="0 0 244 241"
      style={{
        fill: "none",
        strokeWidth: "8px",
      }}
    >
      <g
        id="Group_418"
        data-name="Group 418"
        transform="translate(-4071 1380.422) rotate(-90)"
      >
        <g
          id="Group_46"
          data-name="Group 46"
          transform="translate(1194.157 4245.368) rotate(-90)"
        >
          <line
            id="Line_45"
            data-name="Line 45"
            className="cls-1"
            y2="118.412"
            transform="translate(51.248)"
          />
          <path
            id="Path_48"
            data-name="Path 48"
            className="cls-1"
            d="M0,0,51.25,51.256,102.5,0"
            transform="translate(0 67.163)"
          />
        </g>
      </g>
    </svg>
  )
}

export default ArrowUpIcon

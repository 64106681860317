import { graphql } from "gatsby"
import React from "react"
import "@styles/homePage.scss"
import { SanityHomePage, BlogCategory } from "@utils/globalTypes"
import SEO from "@components/SEO"
import CarouselComponent from "@components/Carousel"
import SimpleSection from "@components/simpleSection"
import AlignedWithLineContentSection from "@components/ContentSection/AlignedWithLineContentSection"
import Image from "gatsby-image"
import HeroWithCTA from "@components/hero/heroWithCta"
import { Row, Col } from "react-bootstrap"
import Img from "react-bootstrap/Image"
import brandPurple from "@assets/svg/Icons_grouped.svg"
import ScrollUpButton from "@components/ScrollUpButton"
import useResizeWidth from "@hooks/useResizeWidth"
import UnalignedContentSection from "@components/ContentSection/UnalignedContentSection"

type Props = {
  data: {
    sanityHome: SanityHomePage
    allSanityBlogCategory: { edges: { node: BlogCategory }[]}
  }
  location: Location
}

const IndexPage = ({ data: { sanityHome, allSanityBlogCategory }, location }: Props) => {
  const { width } = useResizeWidth()
  const { backgroundColor } = sanityHome
  const metadata = {
    location: location.href,
    ...sanityHome?.seo,
  }

  return (
    <>
      <SEO {...metadata} />
      {sanityHome && (
        <div
          style={backgroundColor ? { backgroundColor: backgroundColor } : {}}
        >
          {
            <section className="homePage">
              <div className="home-carrousel-bg">
                {sanityHome.carousel && (
                  <CarouselComponent sanityCarousel={sanityHome.carousel} />
                )}
              </div>
              {sanityHome.simpleSection && (
                <SimpleSection sanitySimpleSection={sanityHome.simpleSection} />
              )}
            </section>
          }
          <section>
            <Row noGutters>
              <Row className="grouped-icon" noGutters></Row>
              <Img
                src={brandPurple}
                fluid
                alt="birchbrook"
                className="home-image"
              />
              <Row
                style={{
                  backgroundColor:
                    sanityHome?.alignedContentSection?.backgroundColor ||
                    "#090D31",
                }}
                className="grouped-icon-external"
                noGutters
              ></Row>
            </Row>
          </section>
          <section>
            {sanityHome.alignedContentSection &&
              sanityHome.alignedContentSection.type === "alignedWithLine" && (
                <AlignedWithLineContentSection
                  contentSection={sanityHome.alignedContentSection}
                />
              )}
            {sanityHome.middleSectionImage && (
              <section className="middleSectionImage-left">
                <Image
                  fluid={sanityHome.middleSectionImage?.asset?.fluid}
                  alt={sanityHome.middleSectionImage?.alt}
                  className="middleSectionImage-left-image"
                />
                <Row className="middleSectionImage-left-row">
                  <Col
                    xl={12}
                    style={{
                      width: "100%",
                      backgroundColor:
                        sanityHome?.alignedContentSection?.backgroundColor ||
                        "#090d31",
                    }}
                  />
                  <Col
                    xl={12}
                    style={{
                      width: "100%",
                      backgroundColor:
                        sanityHome?.unalignedContentSection?.backgroundColor ||
                        "#fff5ec",
                    }}
                  />
                </Row>
              </section>
            )}
            {sanityHome.unalignedContentSection &&
              sanityHome.unalignedContentSection.type === "unaligned" && (
                <UnalignedContentSection
                  contentSection={sanityHome.unalignedContentSection}
                />
              )}
          </section>
          {sanityHome.Image && (
            <Image
              fluid={sanityHome.Image?.asset?.fluid}
              alt={sanityHome.Image.alt}
            />
          )}
          {sanityHome.heroWithCta && (
            <HeroWithCTA sanityHeroWithCta={sanityHome.heroWithCta} />
          )}
          {width > 550 && <ScrollUpButton type="dark" />}
        </div>
      )}
    </>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allSanityBlogPost {
      edges {
        node {
          title
          date
          author
          slug {
            current
          }
          _rawCategory(resolveReferences: { maxDepth: 10 })
          _rawArticle(resolveReferences: { maxDepth: 10 })
          image {
            asset {
              fluid(maxWidth: 10000) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
    sanityHome(_id: { in: ["drafts.homePage", "homePage"] }) {
      seo {
        description
        keywords
        image
        title
        twitterCard
        twitterCreator
        twitterSite
      }
      slug {
        current
      }
      title
      backgroundColor

      carousel {
        _type
        title
        content {
          image {
            alt
            asset {
              fluid(maxWidth: 10000) {
                ...GatsbySanityImageFluid
              }
            }
          }
          _rawTitle(resolveReferences: { maxDepth: 10 })
        }
      }
      alignedContentSection {
        _type
        title
        type
        content {
          ... on SanityBlogPost {
            _type
            id
            _rawArticle(resolveReferences: { maxDepth: 10 })
            slug {
              current
            }
            title
            author
            image {
              alt
              asset {
                fluid(maxWidth: 10000) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          ... on SanityContentItem {
            _rawContent(resolveReferences: { maxDepth: 10 })
            _type
          }
        }
        ctaButton {
          buttonType
          linkUrl
          title
        }
        backgroundColor
      }
      unalignedContentSection {
        
        _type
        title
        type
        content {
          ... on SanityBlogPost {
            _type
            id
            _rawArticle(resolveReferences: { maxDepth: 10 })
            slug {
              current
            }
            title
            date
            author
            image {
              alt
              asset {
                fluid(maxWidth: 10000) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          ... on SanityContentItem {
            _rawContent(resolveReferences: { maxDepth: 10 })
            _type
          }
        }
        ctaButton {
          buttonType
          linkUrl
          title
        }
        backgroundColor
      }
      heroWithCta {
        _type
        arrowUpIcon
        ctaButtons {
          buttonType
          linkUrl
          title
        }
        backgroundColor
        _rawContent(resolveReferences: { maxDepth: 10 })
        image {
          alt
          asset {
            fluid(maxWidth: 10000) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      Image {
        _type
        alt
        asset {
          fluid(maxWidth: 10000) {
            ...GatsbySanityImageFluid
          }
        }
      }
      simpleSection {
        _type
        title
        _rawContent(resolveReferences: { maxDepth: 10 })
        backgroundColor
      }
      middleSectionImage {
        asset {
          fluid(maxWidth: 10000) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`

import React from "react"
import "./index.scss"
import { SanityCarousel } from "@utils/globalTypes"
import { Carousel, Row, Col } from "react-bootstrap"
import Image from "gatsby-image"
import { BaseBlockContent } from "@utils/richText"
import LeaveIcon from "@assets/svg/Birchbrook_Icon_Purple.svg"
import Img from "react-bootstrap/Image"

type Props = {
  sanityCarousel: SanityCarousel
}

const CarouselComponent = ({ sanityCarousel: { content } }: Props) => {
  return (
    <section>
      <Carousel
        interval={3000}
        indicators={content?.length > 1}
        controls={false}
        draggable
        pause={false}
      >
        {content &&
          content.map((item, index) => (
            <Carousel.Item key={index} className="carouselLayout">
              <Row noGutters>
                {item?.image && (
                  <>
                    <Col lg={12} md={12} sm={12} xs={12} className="imageCarousel">
                      <Image
                        fluid={item?.image?.asset?.fluid}
                        alt={item?.image?.alt}
                        imgStyle={{ right: "0px", left: "auto" }}
                      />
                    </Col>
                    <div className="carousel-content">
                      <Img
                        src={LeaveIcon}
                        className="leaveIcon"
                        fluid
                        alt="birchbrook"
                      />
                      <BaseBlockContent blocks={item._rawTitle} />
                    </div>
                  </>
                )}
              </Row>
            </Carousel.Item>
          ))}
      </Carousel>
    </section>
  )
}

export default CarouselComponent
